    /* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */
(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var cre = {
        // All pages
        common: {
            init: function() {

                /* from global.js *********************/
                
                // Show nav after Cufon
                $('#nav').show();

                // Add class="first" to all first list elements
                $('ul li:first-child').addClass('first');

                // Add the '|' separator to the footer nav
                $('#footer .middle li:not(:last-child)').append('<span>&nbsp;|&nbsp;</span>');

                

                /* from inner.js *********************/
                

                //added Nov 29 by Ian - so they can add links in the captions.  see here: 
                //http://wordpress.org/support/topic/adding-links-to-image-captions
                $('p.wp-caption-text').each(function(n) {
                      this.innerHTML = this.innerHTML.replace(new RegExp("{link:([^}]*)}([^{]*){/link}"), "<a href=\"$1\" target=\"_blank\">$2</a>");
                });

                $('#searchform .submit').attr('value', 'Search >>');

                // Fancybox
                $('a.fancybox').fancybox({
                        titlePosition   :   'inside'
                    });
                
                
                $('#infoboxes a.more').on('click', function(e){
                    console.log('h');
                    var content_div = $(this).parents('div.header').next('div.content');
                    var ext_div = content_div.find('div.extended').first();
                    var init_div = content_div.find('div.initial').first();
                    
                    if($(this).hasClass('active')){
                        ext_div.animate({ height: "hide" },200);
                        $(this).text('VIEW MORE');
                        $(this).removeClass('active');
                    }else{
                        ext_div.animate({ height: "show" },200);
                        $(this).text('VIEW LESS');
                        $(this).addClass('active');
                    }
                    e.preventDefault();
                });

                
                // Investigations Sidebar Menu
                var $masterUL = $('#sidebar_left ul.twolevels');
                // look and feel
                $masterUL.find('ul li:last-child').css('border','none');
                $masterUL.find('>li.current>a').addClass('expanded');

            }
        },
        // Home page
        home: {
            init: function() {
                var $sliderspeed = $('.js-slideshow').data('speed');
                if ( $sliderspeed < 3 ) {
                    $sliderspeed = 3;
                }
                $('.js-slideshow').cycle({
                    timeout:  $sliderspeed * 1000,
                    pager: '#pager'
                });

                // from home.js
                
                // Investigations Homepage Caroussel
                $("#investigations .jCarousel").jCarouselLite({
                    btnNext: "#investigations .next",
                    btnPrev: "#investigations .prev",
                    visible: 3,
                    scroll: 1
                });
                //fix slides heights
                $('#investigations li').css('height', 'auto');


                // Slideshow nav hover
                var width_offset = -18;
                var top_offset = 19;
                var left_offset = 14; 
                $hover_u = $('#slideshow div.hover_u');
                $('#slideshow .nav a').bind('mouseenter', function(){
                    pos = $(this).position();
                    w = $(this).width(); 
                    
                    $hover_u.stop(true,true).css({
                        left: pos.left + left_offset,
                        top: pos.top + top_offset,
                        width: w + width_offset
                    }).fadeIn('fast'); 
                });
                
                $('#slideshow .nav a').bind('mouseleave', function(){
                    if( $hover_u.is(':visible') ){
                        $hover_u.stop(true,true).fadeOut('fast');
                    }
                });
                
                $('.js-equalize').equalize();
            }
        },
        // Posts page
        posts_page: {
            init: function() {
                // console.log("posts page");
                // JavaScript to be fired on posts page
            }
        },
        // About us page, note the change from about-us to about_us.
        about_us: {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var namespace = cre;
            funcname = (funcname === undefined) ? 'init' : funcname;
            if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            UTIL.fire('common');

            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
            });
        }
    };

    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.